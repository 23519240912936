<!--
 * @Author: 鲁金伟 lujinwei@hikvision.com.cn
 * @Date: 2022-05-07 16:23:18
 * @LastEditors: 鲁金伟 lujinwei@hikvision.com.cn
 * @LastEditTime: 2022-05-09 17:12:03
 * @FilePath: \btmedtec\src\pages\videoPlayer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main">
    <video-player class="video-player vjs-custom-skin"
      :playsinline="true"
      :options="playerOptions">
    </video-player>
  </div>
</template>

<script>
export default {
  name: 'player',
  data() {
    return {
      playerOptions: {
      }
    }
  },
  mounted() {
    let src = this.$route.query.fileName
    this.playerOptions = {
      playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0],
      autoplay: true,
      muted: true,
      loop: true,
      preload: 'auto',
      language: 'zh-CN',
      aspectRatio: '16:9',
      fluid: true,
      sources: [{
        type: 'video/mp4',
        src: require(`@/assets/video/${src}`)
      }],
      poster: '', // 封面地址
      notSupportedMessage: '此视频暂无法播放，请稍后再试',
      controlBar: {
        timeDivider: true,
        durationDisplay: true,
        remainingTimeDisplay: false,
        fullscreenToggle: true
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 375px;
  margin-top: 24px;
}
</style>
